import { createTheme } from '@mui/material';
import {
  BancalivColors,
  CredinverColors,
  CredipressColors,
  CreditooColors,
  FibancaColors,
  FieColors,
  ZaliColors,
  FinnvoColors,
  FondoColors,
  FreendColors,
  GymColors,
  InversionesColors,
  LenddiColors,
  LiberateColors,
  MaxcreditColors,
  MiliColors2,
  NauwColors,
  SofiColors,
  TuSolucionColors,
  LibramonedaColors,
  FinanzasColors,
  OrquideaColors,
  CreceValorColors,
  SuValorColors,
  FinzapColors,
  ProcordColors,
} from './colors';
import { selectClientName } from '../utils';

const selectTheme = () => {
  const selectedTheme = selectClientName();
  switch (selectedTheme) {
    case 'local':
      return MiliColors2;
    case 'orquidea':
      return OrquideaColors;
    case 'inversiones':
      return InversionesColors;
    case 'finzap':
      return FinzapColors;
    case 'procord':
      return ProcordColors;
    case 'zali':
      return ZaliColors;
    case 'libramoneda':
      return LibramonedaColors;
    case 'mili':
      return MiliColors2;
    case 'finnvo':
      return FinnvoColors;
    case 'bancaliv':
      return BancalivColors;
    case 'creditoo':
      return CreditooColors;
    case 'fie':
      return FieColors;
    case 'fibanca':
      return FibancaColors;
    case 'fondo':
      return FondoColors;
    case 'tusolucion':
      return TuSolucionColors;
    case 'credipress':
      return CredipressColors;
    case 'liberate':
      return LiberateColors;
    case 'nauw':
      return NauwColors;
    case 'lenddi':
      return LenddiColors;
    case 'gym':
      return GymColors;
    case 'credinver':
      return CredinverColors;
    case 'freend':
      return FreendColors;
    case 'maxcredit':
      return MaxcreditColors;
    case 'sofi':
      return SofiColors;
    case 'finanzas':
      return FinanzasColors;
    case 'suvalor':
      return SuValorColors;
    case 'crecevalor':
      return CreceValorColors;
    default:
      return MiliColors2;
  }
};

const colorMainLocalStorage = window.localStorage.getItem('colorsTheme');

const options: any = {
  palette: colorMainLocalStorage ? JSON.parse(colorMainLocalStorage) : selectTheme(),
};

const theme = createTheme(options);

export default theme;
